@use 'styles/mixins.module.scss' as mxn;

.UserStatsForm {
  &__checkbox {
    margin-right: 10px;
  }

  &__frequencyLevelInput {
    width: 2em;
    @include mxn.lineInput;
  }

  &__usernameInput {
    width: calc(100% - 100px);
    @include mxn.lineInput;
  }

  &__submitButton {
    width: 100%;
    margin: 10px 0;
  }
}