@use 'styles/mixins.module.scss' as mxn;

.GenitivePage {
  @include mxn.contentPositioning;
  width: fit-content;

  &__logoutButton {
    margin: 0;
    width: 100%;
  }
}