.GenitiveForm {
  margin: 0 0 20px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.GenitiveForm__oneTask {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
}

.GenitiveForm__word_correct, .GenitiveForm__word_incorrect {
  border-radius: 8px;
  padding: 2px 4px;
  background-color: white;
  font-size: large;
  cursor: pointer;
}

.GenitiveForm__word_correct[done="yes"] {
  background-color: green;
}

.GenitiveForm__word_incorrect[done="yes"] {
  background-color: red;
}

.GenitiveForm__buttonsBlock {
  grid-column: span 3;
}

.GenitiveForm__newWordsButton {
  width: 100%;
  display: block;
}