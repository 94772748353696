@use 'styles/variables.module.scss' as var;

@mixin contentPositioning {
  position: relative;
  //width: fit-content;
  width: calc(100% - 40px);
  margin: auto;
  border-radius: var.$global-border-radius;
  box-sizing: border-box;
  background-color: var.$color-content-background;
  padding: 2em;
  @include animationFadeIn(0.5s);
}

@mixin animationFadeIn($time) {
  animation: fadein ($time);

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
}

@mixin background-color-transition($time) {
  transition: background-color ease-out ($time);
}

@mixin shadow {
  box-shadow: 1px 0 6px rgba(0, 0, 0, 0.18);
}

@mixin font-tiny {
  font-size: var.$font-tiny;
}

@mixin font-small {
  font-size: var.$font-small;
}

@mixin font-medium {
  font-size: var.$font-medium;
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin rounded-square($size) {
  @include square($size);
  border-radius: var.$global-border-radius;
}

@mixin svgBackground($svgurl) {
  background-image: url($svgurl);
  background-repeat: no-repeat;
  background-position: center;
}

@mixin loader($size) {
  @include square(16px * $size);
  margin: 0 auto;
  border: var.$loader-thickness * ($size + 1) solid var.$color-loader;
  border-right: var.$loader-thickness * ($size + 1) solid transparent;
  border-radius: 20px * $size;
  animation: spin 1000ms infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
}

@mixin lineInput {
  margin-left: 0.5em;
  font-size: inherit;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid black;
}