@use 'styles/variables.module.scss' as var;
@use 'styles/mixins.module.scss' as mxn;

body {
  font-family: sans-serif;
  @include mxn.font-medium;
  background: linear-gradient(270deg, rgba(229, 240, 75, 0.05), rgba(19, 109, 161, 0.05));
  background-size: 400% 400%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}