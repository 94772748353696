$color-app-background: #F5F5F5;
$color-content-background: #FFFFFF;
$color-white: #FFFFFF;
$color-bright-pink:#FFDCDC;
$color-pink: #FFA1A1;
$color-red: #FF0000;
$color-light-grey: #F3F3F3;
$color-dark-grey: #3B3B3B;
$color-black: #0D0D0D;
$color-button-text: #0D0D0D;
$color-button-text-disabled: #B6B6B6;

$color-button-background: linear-gradient(270deg,  rgba(229, 240, 75, 0.1), rgba(19, 109, 161, 0.1));
$color-loader: rgba(19, 109, 161, 0.9);

$font-tiny: 12px;
$font-small: 14px;
$font-medium: 18px;

$global-border-radius: 7px;

$loader-thickness: 1.5px;
