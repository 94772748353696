.StressForm {
  margin: 0 0 20px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.StressForm__oneTask {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}

.StressForm__word_correct, .StressForm__word_incorrect {
  border-radius: 8px;
  padding: 2px 4px;
  background-color: white;
  font-size: large;
  cursor: pointer;
}

.StressForm__word_correct[done="yes"] {
  background-color: rgba(2, 190, 2, 0.6);
}

.StressForm__word_incorrect[done="yes"] {
  background-color: rgba(255, 0, 0, 0.6);
}

.StressForm__buttonsBlock {
  grid-column: span 2;
}

.StressForm__newWordsButton {
  width: 100%;
  display: block;
}