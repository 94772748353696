@use 'styles/mixins.module.scss' as mxn;

.AddNewUserForm {
  &__submitButton {
    width: 100%;
    margin: 10px 0;
  }

  &__newUsernameInput {
    width: calc(100% - 100px);
    @include mxn.lineInput;
  }

  &__ageInput {
    width: 2em;
    @include mxn.lineInput;
  }
}