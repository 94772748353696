@use 'styles/mixins.module.scss' as mxn;

.LoginPage {
  @include mxn.contentPositioning;
  max-width: 400px;

  &__usernameInput {
    width: calc(100% - 100px);
    @include mxn.lineInput;
  }

  &__submitButton {
    margin: 10px 0;
    width: 100%;
  }
}